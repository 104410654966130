/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon32 from "../images/favicon32.png"
import favicon16 from "../images/favicon16.png"
import favicon64 from "../images/favicon64.png"
import socialBanner from "../images/metaImage.jpg"

function SEO({ description, lang, meta, title, image, path }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
          }
        }
      }
    `
  )
  // const languages = ["nl", "en"]
  // See helmet example:
  //   {locales.map((locale) => {
  //     <link rel="alternate" href="http://example.com/{locale}" hrefLang={locale} key={locale}/>
  // })}

  const defaultPath = (path) => {
    if (path.startsWith("/en") === true) {
      return path.substring(3)
    } else {
      return path
    }
  }

  // const defaultCanonical = (path) => {
  //   if (path.startsWith("/en") === true) {
  //     return path.substring(3)
  //   } else {
  //     return path
  //   }
  // }

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          // name: `robots`,
          // content: `noindex`,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `image`,
          content: socialBanner,
        },
      ].concat(meta)}
      link={[
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: `${favicon16}`,
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: `${favicon32}`,
        },
        { rel: "shortcut icon", type: "image/png", href: `${favicon64}` },
        // {
        //   rel: "canonical",
        //   href: `https://www.faceinstitute.nl${defaultCanonical(path)}`,
        // },
        {
          rel: "alternate",
          hreflang: `nl`,
          href: `https://www.faceinstitute.nl${defaultPath(path)}`,
        },
        {
          rel: "alternate",
          hreflang: `en`,
          href: `https://www.faceinstitute.nl/en${defaultPath(path)}`,
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
