import { createMuiTheme } from '@material-ui/core/styles'

//Override Material component font-family
const FIMuiTheme = createMuiTheme({
    typography: {
      fontFamily: [
        '"Helvetica Neue"',
        '"Helvetica"',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
        },
      },
    }
    // A way to override toolbar styles
    // overrides:{
    // MuiToolbar: {
    //     regular: {
    //       backgroundColor: "#ffff00",
    //       color: "#000000",
    //       height: "132px",
    //       minHeight: "32px",
    //       padding: 'none',
    //       width: '100%',
    //       '@media (min-width: 600px)': {
    //         minHeight: "48px"
    //       }
    //     }
    // }},
  });

  export default FIMuiTheme