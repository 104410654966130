import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles/index"
import { AppBar, Toolbar } from "@material-ui/core/index"
import { Link } from "gatsby"
import MenuIcon from "@material-ui/icons/Menu"
import logo from "../images/Face-institute-logo.png"
import IconButton from "@material-ui/core/IconButton/IconButton"
import NavDrawer from "./nav-drawer"
import { injectIntl } from "gatsby-plugin-intl"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

const useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: "rgba(0,0,0,0.6)",
    color: "#fff",
    margin: "auto",
    maxWidth: "1920px",
  },
  appBarBlack: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: "black",
    color: "#fff",
    margin: "auto",
    maxWidth: "1920px",
  },
  toolbar: {
    flexWrap: "wrap",
    minHeight: "90px",
  },
  toolbarTitle: {
    flexGrow: 1,
    marginLeft: "3em!important",
  },
  link: {
    margin: theme.spacing(1, 4.5),
    // color: 'white',
    "&:hover": {
      textDecoration: "none",
    },
  },
  bookNowButton: {
    color: "#fff",
    fontSize: "12px",
    backgroundColor: "#9B969A",
    borderRadius: "20px",
    padding: "0.5em 1em",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      opacity: 0.5,
      backgroundColor: "#9B969A",
    },
  },
  logo: {
    marginLeft: "1.8em",
    width: "120px",
    ["@media (max-width:780px)"]: {
      marginLeft: "-1em",
    },
  },
  title: {
    visibility: "hidden",
  },
  hamburger: {
    color: "white",
  },
  localButton: {
    color: "white !important",
    // textTransform: "none",
    fontSize: "1em",
  },
  localMenu: {
    backgroundColor: "rgba(0,0,0,0.6)",
    borderRadius: 0,
  },
  localMenuItem: {
    color: "white",
  },
  localLink: {
    textDecoration: "none",
    color: "white",
    "&:hover": {
      textDecoration: "none",
    },
  },
  label: {
    color: "white",
  },
}))
const mobileMenuId = "primary-search-account-menu-mobile"

const MainToolbar = ({ intl, black }) => {
  const classes = useStyles()
  const [navOpen, setNavOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [local, setLocal] = useState(
    intl.locale === "nl" ? "nl" : `${intl.locale}`
  )

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <NavDrawer open={navOpen} close={() => setNavOpen(false)} />
      <AppBar
        position="static"
        color="default"
        elevation={1}
        className={black ? `${classes.appBarBlack}` : `${classes.appBar}`}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarTitle}>
            <Link
              variant="button"
              to={intl.locale === "nl" ? "/" : `/${intl.locale}/`}
            >
              <img
                src={logo}
                className={classes.logo}
                alt="Face Institute"
              ></img>
            </Link>
          </div>
          <div>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              // className={classes.localButton}
              classes={{
                root: classes.root, // class name, e.g. `classes-nesting-root-x`
                label: classes.label, // class name, e.g. `classes-nesting-label-x`
              }}
            >
              {local}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              classes={{
                paper: classes.localMenu,
                list: classes.localMenuItem,
              }}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem classes={classes.localMenuItem} onClick={handleClose}>
                <Link variant="button" to={"/"} className={classes.localLink}>
                  Dutch
                </Link>
              </MenuItem>
              <MenuItem classes={classes.localMenuItem} onClick={handleClose}>
                <Link
                  variant="button"
                  to={"/en/"}
                  className={classes.localLink}
                >
                  English
                </Link>
              </MenuItem>
            </Menu>
          </div>
          <nav>
            <IconButton
              className={classes.hamburger}
              aria-label="Show menu"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={() => setNavOpen(true)}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </nav>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default injectIntl(MainToolbar)
