import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import React from "react"
import { makeStyles } from "@material-ui/core"
import { Helmet } from "react-helmet"
import { Button } from "@material-ui/core/index"
import nvcg from "../images/medicalAestheticsv2.png"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import { Link } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

const useStyles = makeStyles((theme) => ({
  footer: {
    color: "#fff",
    backgroundColor: "#0E0E0E",
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "transparent",
    justifyContent: "space-between",
    minHeight: "350px",
    minWidth: "176px",
    [theme.breakpoints.down("xs")]: {
      minHeight: "200px",
      marginTop: "24px",
      marginBottom: "24px",
      alignItems: "center",
    },
  },
  nvcgimg: {
    width: 250,
    marginLeft: "5%",
  },
  since: {
    marginLeft: "5%",
    [theme.breakpoints.down("xs")]: {
      // marginTop: "24px",
    },
  },
  bookNowButton: {
    color: "#fff!important",
    fontSize: "14px!important",
    backgroundColor: "#929B91",
    borderRadius: "40px!important",
    padding: "1.0em 4em!important",
    textDecoration: "none!important",
    "&:hover": {
      textDecoration: "none!important",
      opacity: "0.5!important",
      backgroundColor: "#929B91!important",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "24px!important",
      marginBottom: "24px!important",
    },
  },
  address: {
    fontStyle: "normal",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
  },
  link: {
    color: "#929B91",
    textDecoration: "none",
    fontSize: "0-9rem",
  },
  linkTo: {
    color: "#565A56",
    textDecoration: "none",
    fontSize: "0-9rem",
  },
  social: {
    position: "relative",
    display: "inline-block",
    top: 4,
    marginLeft: 20,
  },
  icons: {
    color: "white",
    paddingLeft: 10,
  },
}))

const Footer = (props) => {
  const classes = useStyles()
  const { intl } = props
  const locale = intl.locale

  const getUrl = (locale, url) => {
    if (locale === "nl") {
      if (url === "") {
        return "/"
      } else {
        // No locale to url with nl
        return "/" + url + "/"
      }
    }
    return `/${locale}/${url}/`
  }

  return (
    <Container maxWidth="xl" componen="footer" className={classes.footer}>
      <Helmet>
        <script
          src="//static.leadpages.net/leadboxes/current/embed.js"
          async
          defer
        ></script>
        {/* Google ADS CONVERSION TRACKING */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-618149115"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-618149115');
          `}
        </script>
      </Helmet>
      <footer>
        <Grid container justify="space-evenly" spacing={4} alignItems="center">
          <Grid item sm={12} md={4} className={classes.leftContainer}>
            <span style={{ visibility: "hidden" }}>
              NVCG GECERTIFICEERD ARTS | COSMETIC DOCTOR
            </span>
            <img src={nvcg} className={classes.nvcgimg}></img>
            <div className={classes.since}>
              <br />
              <br />
              &copy; 2024 FACE INSTITUTE
              <div className={classes.social}>
                <span>
                  {" "}
                  <a
                    href="https://www.facebook.com/faceinstitute.nl"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.icons}
                  >
                    <FacebookIcon />
                  </a>
                </span>
                <span>
                  <a
                    href="https://www.instagram.com/faceinstitute.nl/"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.icons}
                  >
                    <InstagramIcon />
                  </a>
                </span>
              </div>
            </div>
          </Grid>
          <Grid item sm={12} md={4} style={{ textAlign: "center" }}>
            <Button
              className={classes.bookNowButton}
              data-leadbox-popup="Yk5iMa5rq33XJXFSgKG5ML"
              data-leadbox-domain="kooldigital.lpages.co"
            >
              Book appointment
            </Button>
          </Grid>
          <Grid
            item
            sm={12}
            md={4}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div style={{ paddingRight: "8%" }}>
              <address className={classes.address}>
                <span className={classes.link}>Address Bussum</span>
                <br />
                Parklaan 2
                <br />
                1406 KL Bussum
                <br />
                <br />
              </address>
              <address className={classes.address}>
                <span className={classes.link}>Address Amsterdam</span>
                <br />
                Frederiksplein 47B
                <br />
                1017 XL Amsterdam
                <br />
                <br />
                <span className={classes.link}>Tel</span>
                <br />
                +31 (0) 85 029 0829
                <br />
                <br />
                <span className={classes.link}>Email</span>
                <br />
                info@faceinstitute.nl
                <br />
                <br />
                <span className={classes.link}>
                  {locale === "nl" ? "Juridisch" : "Legal"}
                </span>
                <br />
                <Link
                  exact
                  className={classes.linkTo}
                  to={getUrl(locale, "terms-and-conditions")}
                >
                  Terms & Conditions
                </Link>
                <br />
                <Link
                  exact
                  className={classes.linkTo}
                  to={getUrl(locale, "complaint-process")}
                >
                  Quality & Complaints
                </Link>
                {/* <Link
                  exact
                  className={classes.linkTo}
                  to={getUrl(locale, "terms-and-conditions")}
                >
                  Complainment process
                </Link> */}
                {/* &copy; 2022 FACE INSTITUTE */}
                {/* <br /> */}
                {/* AMSTERDAM<br/><br/> */}
              </address>
            </div>
          </Grid>
        </Grid>
      </footer>
    </Container>
  )
}

export default injectIntl(Footer)
