import React from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import FIMuiTheme from "../muiTheme"
import MainToolbar from "./main-toolbar"
import Footer from "./footer"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#000",
    },
  },
  content: {
    // padding: 0,
  },
}))

export default (props) => {
  return (
    <>
      <MuiThemeProvider theme={FIMuiTheme}>
        <CssBaseline />
        {/* <Container maxWidth="xl" className={props.classes}> */}
        {/* <MainToolbar/> */}
        {/* <Container maxWidth="xl" className={classes.content}> */}
        {props.children}
        {/* </Container> */}
        {/* </Container> */}
        <Footer />
      </MuiThemeProvider>
    </>
  )
}

// GOOOGLEE, Add{* } type comment on google Global site.. And add thia before MuiThemeProv..
{
  /* <Helmet>
  Global site tag (gtag.js) - Google Ads: 618149115
  <script
    async
    src="https://www.googletagmanager.com/gtag/js?id=AW-618149115"
  ></script>
  <script>
    {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-618149115');`}
  </script>
</Helmet> */
}

//Material UI break points:
// xs (extra-small): 0px or larger
// sm (small): 600px or larger
// md (medium): 960px or larger
// lg (large): 1280px or larger
// xl (extra-large): 1920px or larger
